import {
  ADD_TO_CART,
  REMOVE_FROM_CART,
  CLEAR_CART,
  CHANGE_FREQUENCY,
  TOGGLE_SUBSCRIPTION,
} from "../actions/action-types";

export default function cartReducer(state, action) {
  let newCart = [...state];

  // check if adding an item that's already in the cart
  let existingIndex;
  if (action.payload) {
    existingIndex = state.findIndex(
      (item) => item.variant === action.payload.variant
    );
  }

  // update cart state (context) and local storage function
  const saveData = (cart) => {
    localStorage.setItem("cart", JSON.stringify(cart));
  };

  switch (action.type) {
    case ADD_TO_CART:
      // update quantity of already added item
      if (existingIndex !== -1) {
        let newQty = newCart[existingIndex].qty + action.payload.qty;
        console.log("new quantity:", newQty);
        console.log("available stock:", action.payload.stock);
        // check if there's sufficient stock and limit quantity to maximum available
        if (action.payload.stock !== null && newQty > action.payload.stock) {
          newQty = action.payload.stock;
          console.log("new quantity limited to available stock:", newQty);
        }

        // update already added item's quantity
        newCart[existingIndex] = { ...newCart[existingIndex], qty: newQty };
      } else {
        // add new item
        newCart.push(action.payload);
      }

      // save cart
      saveData(newCart);
      return newCart;

    case REMOVE_FROM_CART:
      //reduce quantity of item in cart
      const newQty = newCart[existingIndex].qty - action.payload.qty;

      // remove item from cart if it becomes zero quantity
      if (newQty <= 0) {
        newCart = newCart.filter(
          (item) => item.variant !== action.payload.variant
        );
      } else {
        // set new reduced quantity
        newCart[existingIndex] = { ...newCart[existingIndex], qty: newQty };
      }

      saveData(newCart);
      console.log("item removed from cart.");
      return newCart;

    //TODO implement subscription
    case CHANGE_FREQUENCY:
      newCart[existingIndex].subscription = action.payload.frequency;
      saveData(newCart);
      return newCart;

    case TOGGLE_SUBSCRIPTION:
      //check for existing subscription
      const existingSubscription = !!newCart[existingIndex].subscription; // !! coerce to boolean

      if (existingSubscription) {
        delete newCart[existingIndex].subscription;
      } else {
        newCart[existingIndex].subscription = action.payload.frequency;
      }

      saveData(newCart);
      return newCart;

    case CLEAR_CART:
      // remove cart from local storage
      localStorage.removeItem("cart");
      console.log("cart cleared.");
      return [];

    default:
      return state;
  }
}
