import { gql } from "@apollo/client";

export const GET_VARIANT_STOCK_INFO = gql`
  query getVariantStockInfo($size: String!) {
    variants(filters: { size: { eq: $size } }) {
      data {
        attributes {
          bundleOnly
          size
          useStock
          qtyStock
          shippingWeightGrams
          numberOfItems
          underlyingItem {
            data {
              attributes {
                size
                useStock
                qtyStock
                shippingWeightGrams
                numberOfItems
                customsDescription
                customsCodeHSCN
                customsOriginCountry
                basePrice
                salePrice
              }
            }
          }
          customsDescription
          customsCodeHSCN
          customsOriginCountry
        }
      }
    }
  }
`;

export const GET_VARIANTS_STOCK_INFO = gql`
  query getVariantsStockInfo($sizes: [String!]!) {
    variants(filters: { size: { in: $sizes } }) {
      data {
        attributes {
          bundleOnly
          size
          useStock
          qtyStock
          shippingWeightGrams
          numberOfItems
          underlyingItem {
            data {
              attributes {
                size
                useStock
                qtyStock
                shippingWeightGrams
                numberOfItems
                customsDescription
                customsCodeHSCN
                customsOriginCountry
                basePrice
                salePrice
              }
            }
          }
          customsDescription
          customsCodeHSCN
          customsOriginCountry
        }
      }
    }
  }
`;

export const GET_BUNDLE_STOCK_INFO = gql`
  query getBundleStockInfo($contents: String!) {
    bundles(filters: { contents: { eq: $contents } }) {
      data {
        attributes {
          contents
          variants {
            data {
              attributes {
                size
                useStock
                qtyStock
                shippingWeightGrams
                numberOfItems
                underlyingItem {
                  data {
                    attributes {
                      size
                      useStock
                      qtyStock
                      shippingWeightGrams
                      numberOfItems
                      customsDescription
                      customsCodeHSCN
                      customsOriginCountry
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_CURRENCY_INFO_BY_CODE = gql`
  query getCurrencyInfoByCode($codeISO: String!) {
    currencies(filters: { codeISO: { eq: $codeISO } }) {
      data {
        attributes {
          name
          symbol
          rateToBase
          decimals
          description
          allowPayments
        }
      }
    }
  }
`;

export const GET_PAYMENT_CURRENCIES = gql`
  query getPaymentCurrencies {
    currencies {
      data {
        attributes {
          name
          symbol
          codeISO
          rateToBase
          decimals
          description
          allowPayments
        }
      }
    }
  }
`;

export const GET_COMMENTS = gql`
  query getComments($id: ID!) {
    article(id: $id) {
      data {
        id
        attributes {
          comments(pagination: { limit: 50 }, sort: "createdAt:desc") {
            data {
              id
              attributes {
                text
                rating
                updatedAt
                commentator
                likers
                allTimeLikers
              }
            }
          }
          viewers
        }
      }
    }
  }
`;

export const GET_PRODUCT_DETAILS = gql`
  query getProductDetails($id: ID!) {
    product(id: $id) {
      data {
        id
        attributes {
          reviews {
            data {
              id
              attributes {
                product {
                  data {
                    id
                    attributes {
                      name
                    }
                  }
                }
                text
                rating
                dateOverride
                updatedAt
                reviewer
                likers
                allTimeLikers
              }
            }
          }
          favorites {
            data {
              id
              attributes {
                userID
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_DISCOUNT_CODES = gql`
  query getDiscountCodes {
    discountCodes(pagination: { limit: 100 }) {
      data {
        id
        attributes {
          code
          description
        }
      }
    }
  }
`;

export const GET_LOYALTY_TIERS = gql`
  query getLoyaltyTiers {
    loyaltyTiers {
      data {
        id
        attributes {
          name
          title
          description
          color
          pointsRequired
          badge {
            data {
              attributes {
                url
                alternativeText
              }
            }
          }
          stamp {
            data {
              attributes {
                url
                alternativeText
              }
            }
          }
          stampOutline {
            data {
              attributes {
                url
                alternativeText
              }
            }
          }
          discount
          freeShipping
          members
        }
      }
    }
  }
`;

export const GET_NOTIFICATION_BANNER = gql`
  query getNotificationBanner {
    notificationBanner {
      data {
        id
        attributes {
          isDisplayed
          displayDurationSeconds
          text
          textColor
          bgColor
          buttonText
          buttonTextColor
          buttonOutlineColor
          buttonColor
          buttonLinkUrl
        }
      }
    }
  }
`;
